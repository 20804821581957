<template>
  <OtherBanner :type="15" />

  <section class="filter-box" id="pageTop">
    <div class="content">
      <!-- 分类 -->
      <TreeFilter
        :name="$t('category')"
        :dataSource="folderData"
        :treeData2="folderTreeData"
        :treePath2="folderTreePath"
        @onChange="folderChange"
      />
      <!-- 完成状态 -->
      <ListFilter
        only
        :name="$t('Pub_Lab_FinishStatus')"
        :dataSource="passStatus"
        @onChange="passStatusChange"
        class="filter-lang"
      />
    </div>
  </section>

  <section class="toolbar">
    <div class="content">
      <div class="crumbs">
        {{ $t("current_position") }}：
        <!-- 当前位置： -->
        <span
          v-for="(item, index) in folderCrumbs"
          :key="item.id"
          @click="crumbsClick(item, index)"
        >
          {{ item.name }}
          <i>></i>
        </span>
      </div>
      <div class="search">
        <div class="search-icon" @click="search">
          <SearchOutlined style="color: #656565" />
        </div>
        <a-input
          class="search-input"
          v-model:value.trim="searchVal"
          :placeholder="$t('please_enter_keywords')"
          allow-clear
          @pressEnter="search"
        />
        <!-- 请输入关键字 -->
      </div>
    </div>
  </section>

  <section class="course">
    <div class="content">
      <a-spin :spinning="courseLoading">
        <template v-if="courseData.length">
          <div class="cards clearfix">
            <div
              class="item fl"
              v-for="item in courseData"
              :key="item.courseId"
            >
              <NewstaffCard :dataSource="item" />
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :defaultPageSize="20"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { useRoute } from "vue-router";
import TreeFilter from "@/components/filter/TreeFilter.vue";
import ListFilter from "@/components/filter/ListFilter.vue";
import OtherBanner from "@/components/banner/OtherBanner.vue";
import NewstaffCard from "@/components/newstaff/NewstaffCard.vue";
import { packageFolder } from "@/api/package";
import { newstaffList } from "@/api/newstaff";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    TreeFilter,
    ListFilter,
    NewstaffCard,
    renderVNode,
    OtherBanner,
  },
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    // 目录
    let folderId = Number(route.query.folderId || 0);
    let folderCrumbs = ref([
      {
        id: 0,
        name: $t("CM_All"),
        // 全部
      },
    ]);
    let folderData = ref([]);

    const getNewObj = (data) => {
      return JSON.parse(JSON.stringify(data));
    };

    let tempFolderCrumbs = [];
    let tempFolderData = [];
    let tempFolderPath = [];
    const traverseTree = (data) => {
      let str = `"id":${folderId},`;
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let strItem = JSON.stringify(item);
        if (strItem.includes(str)) {
          tempFolderCrumbs.push(item);
          tempFolderData.push([
            {
              id: tempFolderData.length == 0 ? 0 : item.id,
              name: $t("CM_All"),
            },
            ...data,
          ]);
          tempFolderPath.push(i);
          if (item.id == folderId && item.list && item.list.length) {
            tempFolderData.push([
              {
                id: tempFolderData.length == 0 ? 0 : item.id,
                name: $t("CM_All"),
              },
              ...item.list,
            ]);
            tempFolderPath.push(-1);
          }
          if (item.id != folderId && item.list && item.list.length) {
            traverseTree(item.list);
          }
          break;
        }
      }
    };

    packageFolder().then((res) => {
      if (res.ret === 0) {
        folderData.value = res.data || [];
        if (folderId) {
          traverseTree(getNewObj(folderData.value));
          folderCrumbs.value = tempFolderCrumbs;
          folderCrumbs.value = getNewObj(tempFolderCrumbs);
          folderTreeData.value = getNewObj(tempFolderData);
          folderTreePath.value = getNewObj(tempFolderPath);
        }
      }
    });

    let folderTreeData = ref([]);
    let folderTreePath = ref([]);
    const folderChange = ({ activeId, crumbs, reload, treeData, treePath }) => {
      folderId = activeId;
      folderCrumbs.value = crumbs;
      folderTreeData.value = treeData;
      folderTreePath.value = treePath;
      getNewstaffList();
    };

    // 完成状态
    const passStatus = [
      { id: 1, name: $t("CM_Completed") }, // 已完成
      { id: 2, name: $t("CM_NotCompleted") }, // 未完成
    ];
    let passStatusActive = [0];
    const passStatusChange = (arr) => {
      passStatusActive = arr;
      getNewstaffList();
    };

    // 面包屑
    const crumbsClick = (data, index) => {
      let dom = document.getElementById(`tree_${index}_${data.id}`);
      dom.click();
    };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getNewstaffList();
      };

    // 新人培训
    const courseData = ref([]),
      courseLoading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getNewstaffList = (scroll = false) => {
        courseLoading.value = true;
        newstaffList({
          page: currentPage.value,
          pageSize: 20,
          name: searchVal.value,
          folderId: folderId,
          order: 1,
          status: [],
          complete: passStatusActive[0] === 0 ? [] : passStatusActive,
        }).then((res) => {
          courseLoading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            courseData.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getNewstaffList(true);
      };
    getNewstaffList();

    return {
      folderData,
      folderTreeData,
      folderTreePath,
      folderCrumbs,
      folderChange,
      crumbsClick,
      passStatus,
      passStatusChange,
      searchVal,
      search,
      getNewstaffList,
      courseData,
      courseLoading,
      currentPage,
      pageTotal,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.filter-box {
  background-color: @color-page-gray;
  padding-bottom: 14px;
  .content {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.15);
    .mixinWrap();
    ::v-deep(.filter-lang) {
      .label {
        width: 78px !important;
      }
      .values {
        width: calc(100% - 88px) !important;
      }
    }
  }
}

.toolbar {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    .mixinFlex(space-between; center);
    padding: 20px 0 16px;
    .crumbs {
      width: calc(100% - 250px);
      font-size: 14px;
      color: #999;
      span {
        cursor: pointer;
        i {
          padding: 0 2px;
        }
        &:hover {
          color: @color-theme;
        }
        &:last-child {
          .noevents();
          i {
            display: none;
          }
          &:hover {
            color: #999;
          }
        }
      }
    }
    .search {
      border: 1px solid #ccc;
      .mixinFlex(space-between; center);
      width: 220px;
      height: 32px;
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      &-icon {
        width: 34px;
        height: 14px;
        .mixinFlex(center; center);
        border-right: 1px solid #bbbbbb;
        cursor: pointer;
      }
      ::v-deep(.ant-input-affix-wrapper-focused) {
        border-color: none;
        box-shadow: none;
      }
      &-input {
        border: none;
        width: calc(100% - 34px);
        height: 100%;
        background-color: #fff;
        padding: 0 8px;
        font-size: 14px;
        ::v-deep(.ant-input) {
          background-color: #fff;
        }
      }
    }
  }
}

.course {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      .item {
        margin-bottom: 24px;
        margin-right: 24px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
